import {
  HOME_SLIDER,
  HOME_SLIDER_ERR,
  BLOG_LIST,
  BLOG_LIST_ERR,
  TESTI_LIST,
  TESTI_LIST_ERR,
  CAREER_LIST,
  CAREER_LIST_ERR,
  PARTNER_LIST,
  PARTNER_LIST_ERR,
  COUNTRY_LIST_ERR,
  COUNTRY_LIST,
  COURSE_CAT_LIST,
  COURSE_CAT_LIST_ERR,
  BLOG_DT,
  BLOG_DT_ERR,
  COUNTRY_DETAIL,
  COUNTRY_DETAIL_ERR,
  EDU_LEVEL,
  EDU_LEVEL_ERR,
  GET_COURSES,
  GET_COURSES_ERR,
  GET_COURSES_DT,
  GET_COURSES_DT_ERR,
  GENERAL_DETAILS,
  GENERAL_DETAILS_ERR,
} from "../types/types";
const initialState = {
  homeSlider: null,
  homeSliderErr: null,
  blog_list: null,
  blog_list_err: null,
  testi_list: null,
  testi_list_err: null,
  career_list: null,
  career_list_err: null,
  partner_list: null,
  partner_list_err: null,
  ctry_list: null,
  ctry_list_err: null,
  course_list: null,
  course_list_err: null,
  blog_dt: null,
  blog_dt_err: null,
  ctry_dt: null,
  ctry_dt_err: null,
  edu_level: null,
  edu_level_err: null,
  courses: null,
  courses_err: null,
  coursesDt: null,
  courses_dt_err: null,
  general_dt: null,
  general_dt_err: null,
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case HOME_SLIDER:
      return {
        ...state,
        homeSlider: actions.payload,
      };
    case HOME_SLIDER_ERR:
      return {
        ...state,
        homeSliderErr: actions.payload,
      };
    case BLOG_LIST:
      return {
        ...state,
        blog_list: actions.payload,
      };
    case BLOG_LIST_ERR:
      return {
        ...state,
        blog_list_err: actions.payload,
      };
    case TESTI_LIST:
      return {
        ...state,
        testi_list: actions.payload,
      };
    case TESTI_LIST_ERR:
      return {
        ...state,
        testi_list_err: actions.payload,
      };
    case CAREER_LIST:
      return {
        ...state,
        career_list: actions.payload,
      };
    case CAREER_LIST_ERR:
      return {
        ...state,
        career_list_err: actions.payload,
      };
    case PARTNER_LIST:
      return {
        ...state,
        partner_list: actions.payload,
      };
    case PARTNER_LIST_ERR:
      return {
        ...state,
        partner_list_err: actions.payload,
      };

    case COUNTRY_LIST:
      return {
        ...state,
        ctry_list: actions.payload,
      };
    case COUNTRY_LIST_ERR:
      return {
        ...state,
        ctry_list_err: actions.payload,
      };

    case COURSE_CAT_LIST:
      return {
        ...state,
        course_list: actions.payload,
      };
    case COURSE_CAT_LIST_ERR:
      return {
        ...state,
        course_list_err: actions.payload,
      };
    case BLOG_DT:
      return {
        ...state,
        blog_dt: actions.payload,
      };
    case BLOG_DT_ERR:
      return {
        ...state,
        blog_dt_err: actions.payload,
      };
    case COUNTRY_DETAIL:
      return {
        ...state,
        ctry_dt: actions.payload,
      };
    case COUNTRY_DETAIL_ERR:
      return {
        ...state,
        ctry_dt_err: actions.payload,
      };
    case EDU_LEVEL:
      return {
        ...state,
        edu_level: actions.payload,
      };
    case EDU_LEVEL_ERR:
      return {
        ...state,
        edu_level_err: actions.payload,
      };
    case GET_COURSES:
      return {
        ...state,
        courses: actions.payload,
      };
    case GET_COURSES_ERR:
      return {
        ...state,
        courses_err: actions.payload,
      };
    case GET_COURSES_DT:
      return {
        ...state,
        coursesDt: actions.payload,
      };
    case GET_COURSES_DT_ERR:
      return {
        ...state,
        courses_dt_err: actions.payload,
      };
    case GENERAL_DETAILS:
      return {
        ...state,
        general_dt: actions.payload,
      };
    case GENERAL_DETAILS_ERR:
      return {
        ...state,
        general_dt_err: actions.payload,
      };

    default:
      return state;
  }
}
