import React, { Component } from "react";

export default class ServiceCard extends Component {
  render() {
    return (
      // <div className="col-lg-4">
        <div className="service_card_wrapper">
          <div className={`img_wrapper ${this.props.bg_color}`}>
            <img src={this.props.img}></img>
          </div>
          <div className="service_info_wrapper">
            <p className="service_heading">{this.props.heading}</p>
            <p className="service_desc">
              Praesent sapien massa, convallis a pellentesque nec, egestas non
              nisi. Curabitur arcu erat, accumsan id imperdiet et, porttitor at
              sem. Vestibulum ante ipsum primis in faucibus orci luctus et
              ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet
              aliquam vel, ullamcorper sit amet ligula. Vestibulum ac diam sit
              amet quam vehicula elementum sed sit amet dui.
            </p>
          </div>
        </div>
      // </div>
    );
  }
}
