import React, { Component } from "react";

export default class Mission extends Component {
  render() {
    return (
      <>
        <div className="container ">
          <div className="row">
            <div className="col-lg-6 col-md-6 d">
              <div className="slider_content_wrapper">
                <div className="slider_content">
                  <div className="circle"></div>
                  <p className="main_head_sm">We Are</p>
                  <h1 className="main_head_lg">OverseasEye</h1>
                  <p className="main_head_desc">
                    Sed porttitor lectus nibh. Quisque velit nisi, pretium ut
                    lacinia in, elementum id enim.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-sm-none d-md-block d-none">
              <div className="slider_image_wrapper">
                <p>
                  Curabitur non nulla sit amet nisl tempus convallis quis ac
                  lectus. Donec sollicitudin molestie malesuada. Pellentesque in
                  ipsum id orci porta dapibus. Nulla quis lorem ut libero
                  malesuada feugiat. Nulla porttitor accumsan
                  tincidunt.Curabitur non nulla sit amet nisl tempus convallis
                  quis ac lectus. Donec sollicitudin molestie malesuada.
                  Pellentesque in ipsum id orci porta dapibus. Nulla quis lorem
                  ut libero malesuada feugiat. Nulla porttitor accumsan
                  tincidunt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
