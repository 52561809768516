import React, { Component } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class WeAre extends Component {
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".we_are",
        start: "top center",
        // markers: true
      },
    });

    tl.fromTo(
      ".anme_item",
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
    tl.fromTo(
      ".banner_bg_color",
      {
        opacity: 0,
        width: 0,
        ease: "power1.in",
      },
      {
        opacity: 1,
        width: "76%",
        ease: "power1.out",
      }
    );
  }
  render() {
    return (
      <>
        <div className="container mt-2 we_are">
          <div className="row">
            <div className="col-lg-6 col-md-6 d">
              <div className="slider_content_wrapper">
                <div className="slider_content">
                  <div className="circle"></div>
                  <p className="main_head_sm anme_item">We Are</p>
                  <h1 className="main_head_lg anme_item">OverseasEye</h1>
                  <p className="main_head_desc anme_item">
                    Sed porttitor lectus nibh. Quisque velit nisi, pretium ut
                    lacinia in, elementum id enim.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-sm-none d-md-block d-none">
              <div className="slider_image_wrapper anme_item">
                <p >
                  Curabitur non nulla sit amet nisl tempus convallis quis ac
                  lectus. Donec sollicitudin molestie malesuada. Pellentesque in
                  ipsum id orci porta dapibus. Nulla quis lorem ut libero
                  malesuada feugiat. Nulla porttitor accumsan
                  tincidunt.Curabitur non nulla sit amet nisl tempus convallis
                  quis ac lectus. Donec sollicitudin molestie malesuada.
                  Pellentesque in ipsum id orci porta dapibus. Nulla quis lorem
                  ut libero malesuada feugiat. Nulla porttitor accumsan
                  tincidunt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
