export const HOME_SLIDER = "HOME_SLIDER";
export const HOME_SLIDER_ERR = "HOME_SLIDER_ERR";

export const BLOG_LIST = "BLOG_LIST";
export const BLOG_LIST_ERR = "BLOG_LIST_ERR";

export const BLOG_DT = "BLOG_DT";
export const BLOG_DT_ERR = "BLOG_DT_ERR";

export const TESTI_LIST = "TESTI_LIST";
export const TESTI_LIST_ERR = "TESTI_LIST_ERR";

export const CAREER_LIST = "CAREER_LIST";
export const CAREER_LIST_ERR = "CAREER_LIST_ERR";

export const PARTNER_LIST = "PARTNER_LIST";
export const PARTNER_LIST_ERR = "PARTNER_LIST_ERR";

export const COUNTRY_LIST = "COUNTRY_LIST";
export const COUNTRY_LIST_ERR = "COUNTRY_LIST_ERR";

export const COURSE_CAT_LIST = "COURSE_CAT_LIST";
export const COURSE_CAT_LIST_ERR = "COURSE_CAT_LIST_ERR";

export const COUNTRY_DETAIL = "COUNTRY_DETAIL";
export const COUNTRY_DETAIL_ERR = "COUNTRY_DETAIL_ERR";

export const EDU_LEVEL = "EDU_LEVEL";
export const EDU_LEVEL_ERR = "EDU_LEVEL_ERR";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_ERR = "GET_COURSES_ERR";


export const GET_COURSES_DT = "GET_COURSES_DT";
export const GET_COURSES_DT_ERR = "GET_COURSES_DT_ERR";

export const GENERAL_DETAILS = "GENERAL_DETAILS";
export const GENERAL_DETAILS_ERR = "GENERAL_DETAILS_ERR";

