import React, { Component } from "react";
import CourseCard from "../components/card/CourseCard";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import ServicesSec from "../pageCmp/home/ServicesSec";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getCareerList,
  getCountryList,
  getEduLevel,
  getCourses,
  getCategoryList,
} from "../redux/actions/dataAction";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import queryString from "query-string";
import isEmpty from "../redux/validation/is-Empty";
import Loader from "../components/commonCmp/Loader";

const placeholder = "Select The Courses...";
const placeholderCtry = "Select Country...";

class AllCourse extends Component {
  constructor() {
    super();
    this.state = {
      career: null,
      ctryList: null,
      ctryId: "",
      eduId: "",
      courses: null,
      ed_level: "",
      category: null,
      catId: "",
      selectedCtry: null,
      selectedCat: null,
    };
    this.cards = [];
  }
  componentDidMount() {
    // let params = queryString.parse(this.props.location.search);
    // console.log(this.props);
    const { params } = this.props.match;
    console.log(params);
    var count = Object.keys(params).length;
    console.log(count);
    if (count === 1) {
      this.setState(
        {
          ctryId: "",
          catId: params.category !== "" ? parseInt(params.category) : "",
        },
        () => this.getCrseList()
      );
    } else if (count === 2) {
      this.setState(
        {
          ctryId: params.country !== "" ? parseInt(params.country) : "",
          catId: params.category !== "" ? parseInt(params.category) : "",
        },
        () => this.getCrseList()
      );
    } else {
      this.getCrseList();
    }

    // if (params) {
    //   this.setState(
    //     {
    //       ctryId: params.country !== "" ? parseInt(params.country) : "",
    //       catId: params.category !== "" ? parseInt(params.category) : "",
    //     },
    //     () => this.getCrseList()
    //   );
    // } else {
    //   this.getCrseList();
    // }
    this.props.getCareerList();

    this.props.getCountryList();
    this.props.getCategoryList();
    gsap.registerPlugin(ScrollTrigger);

    this.tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".all_crse",
        start: "top center",
        markers: false,
      },
    });
    this.tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
  }
  getCrseList() {
    this.props.getCourses({
      education_level_id: this.state.eduId,
      category: this.state.catId,
    });
    if (this.state.ctryId !== "" || this.state.ctryId !== null) {
      this.props.getEduLevel({ country: this.state.ctryId });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.cat !== this.props.cat) {
      this.setState(
        {
          category: nextProps.cat,
        },
        () => this.getParamValue()
      );
    }
    if (nextProps.career !== this.props.career) {
      this.setState({
        career: nextProps.career,
      });
      this.tl.play();
    }

    if (nextProps.crty_lst !== this.props.crty_lst) {
      if (nextProps.crty_lst) {
        this.setState(
          {
            ctryList: nextProps.crty_lst,
          },
          () => this.getParamValue()
        );
      }
    }
    if (nextProps.courses !== this.props.courses) {
      if (nextProps.courses) {
        this.setState({
          courses: nextProps.courses,
        });
      }
    }
    if (nextProps.ed_level !== this.props.ed_level) {
      if (nextProps.ed_level) {
        this.setState({
          ed_level: nextProps.ed_level,
        });
      }
    }
  }
  getParamValue() {
    if (!isEmpty(this.state.ctryList)) {
      var ctrye = null;
      this.state.ctryList.map((ctry) => {
        if (ctry.id === this.state.ctryId) {
          ctrye = { value: ctry.id, label: ctry.name };
        }
        this.setState({
          selectedCtry: ctrye,
        });
      });
    }
    if (!isEmpty(this.state.category)) {
      var catee = null;
      this.state.category.map((ctry) => {
        if (ctry.id === this.state.catId) {
          catee = { value: ctry.id, label: ctry.name };
        }
        this.setState({
          selectedCat: catee,
        });
      });
    }
  }
  selectCounrty(value) {
    this.setState(
      {
        ctryId: value.value,
      },
      () => this.getParamValue()
    );
    this.props.getEduLevel({ country: value.value });
  }
  edChange(value) {
    this.setState({
      eduId: value.value,
    });
    this.props.getCourses({
      education_level_id: value.value,
      category: this.state.catId,
    });
    // this.props.getEduLevel({ country: value.value });
  }
  selectCategory(value) {
    this.setState(
      {
        catId: value.value,
      },
      () => this.getParamValue()
    );
    this.props.getCourses({
      education_level_id: this.state.eduId,
      category: value.value,
    });
  }
  render() {
    // var ctrys = [{ value: "", label: "Select Country" }];
    var ctrys = [];
    var ed_lvl = [];
    var cate = [];
    if (this.state.ctryList) {
      this.state.ctryList.map((ctry, i) =>
        ctrys.push({ value: ctry.id, label: ctry.name })
      );
    }
    if (this.state.ed_level) {
      this.state.ed_level.map((ed, i) =>
        ed_lvl.push({ value: ed.id, label: ed.name })
      );
    }
    if (this.state.category) {
      this.state.category.map((ed, i) =>
        cate.push({ value: ed.id, label: ed.name })
      );
    }

    console.log(this.state);
    return this.state.courses ? (
      <>
        <TopNav></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper all_crse mt-0">
          <div className="course_list_wrapper">
            <div className="all_filter_course_wrapper">
              <img
                src={
                  '../../'+process.env.PUBLIC_URL + "assets/images/imgs/course_bg.png"
                }
              ></img>
              <div className="form_wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <Select
                        value={this.state.selectedCat}
                        onChange={(v) => this.selectCategory(v)}
                        options={cate}
                        placeholder={placeholder}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Select
                        value={this.state.selectedCtry}
                        onChange={(v) => this.selectCounrty(v)}
                        options={ctrys}
                        placeholder={placeholderCtry}
                      />
                    </div>
                    <div className="col-lg-4">
                      {/* <select
                        class="form-select w-100 overseas_select mb-3"
                        aria-label="Default select example"
                      >
                        <option selected>Select Degree Level...</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select> */}
                      <Select
                        // value={selectedOption}
                        onChange={(v) => this.edChange(v)}
                        options={ed_lvl}
                        placeholder={placeholder}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-5 mb-5 testt">
              <h4>All Courses</h4>
              <p className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididun. Quis ipsum suspendisse ultrices
                gravida. Risus commodo viverra maecenas accumsan lacus vel
                facilisis.{" "}
              </p>
              {this.state.courses.map((c, i) => (
                <div
                  className="course_card_wrapper"
                  ref={(div) => (this.cards[i] = div)}
                  key={i}
                  key={i}
                >
                  <CourseCard
                    history={this.props.history}
                    data={c}
                    key={i}
                  ></CourseCard>
                </div>
              ))}
              {/* <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard> */}
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    ) : (
      <Loader></Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  career: state.data.career_list,
  crty_lst: state.data.ctry_list,
  courses: state.data.courses,
  ed_level: state.data.edu_level,
  cat: state.data.course_list,
});

export default connect(mapStateToProps, {
  getCareerList,
  getCountryList,
  getEduLevel,
  getCourses,
  getCategoryList,
})(AllCourse);
