import React, { Component } from "react";

export default class BlogsCard extends Component {
  render() {
    return (
      // <div className="col-lg-3 mb-4">
        <div
          className="blog_card_wrapper "
        onClick={() => this.props.history.push("/blogs/"+this.props.blg.slug)}
        >
          {/* <div className="image_wrapper"> */}
          <img
            src={
              this.props.blg.image_url
            }
          ></img>
          <div className="blog_name_wrapper">
            <p className="blog_date">
              {new Date(this.props.blg.created_at).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })}
            </p>
            <p className="blog_title">{this.props.blg.title}</p>
          </div>
        </div>
        // {/* </div> */}
      // </div>
    );
  }
}
