import "./assets/css/overseas.css";
import AllCourse from "./pages/AllCourse";
import Blog from "./pages/Blog";
import CourseDetails from "./pages/CourseDetails";
import Home from "./pages/Home";
import Services from "./pages/Services";
import StudyAbrod from "./pages/StudyAbrod";
import AbroadInner from "./pages/AbroadInner";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import General from "./pages/General";
import ScrollToTop from "../src/pages/ScrollToTop";
import CourseCategory from "./pages/CourseCategory";
import About from "./pages/About";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import {useRef, useEffect } from "react";

function App() {
  // gsap.registerPlugin(ScrollTrigger);
  // const ref = useRef(null);
  // useEffect(() => {
  //   const element = ref.current;
  //   var tl = gsap.timeline({repeat: 2, repeatDelay: 1});
  //   var cnt_anime = element.querySelector(".content_desc_wrapper .content_container");
  //   tl.fromTo(
  //     cnt_anime,
  //     {
  //       opacity: 0,
  //       y: -20
  //     },
  //     {
  //       opacity: 1,
  //       y: 0,
  //       scrollTrigger: {
  //         trigger: element.querySelector(".cmn_anime"),
  //         start: "top center",
  //         end: "bottom bottom",
  //         once:true,
  //         scrub: 1,
  //         ease: "power1.out",
  //         markers: true
  //       }
  //     }
  //   );
  // }, []);

  return (
    <Provider store={store}>
      <div className="App">
        {/* <Home></Home> */}
        {/* <Services></Services> */}
        {/* <Blog ></Blog> */}
        {/* <StudyAbrod></StudyAbrod> */}
        {/* <AllCourse></AllCourse> */}
        {/* <CourseDetails></CourseDetails> */}
        {/* <AbroadInner></AbroadInner> */}
        {/* <BlogDetails></BlogDetails> */}
        <Router>
          <ScrollToTop>
            <Switch>
              <Route path="/" exact component={Home}></Route>
              {/* <Route path="/:slug" exact component={Home}></Route> */}
              <Route path="/services" exact component={Services}></Route>
              <Route path="/about-us" exact component={About}></Route>
              <Route path="/blogs" exact component={Blog}></Route>
              <Route path="/all-course" exact component={AllCourse}></Route>
              <Route path="/study-abroad" exact component={StudyAbrod}></Route>
              <Route
                path="/study-abroad/:slug"
                exact
                component={AbroadInner}
              ></Route>
              <Route path="/general/:slug" exact component={General}></Route>
              <Route path="/courses" exact component={CourseCategory}></Route>
              <Route path="/find-courses" exact component={AllCourse}></Route>
              <Route
                path="/find-courses/:country/:category"
                exact
                component={AllCourse}
              ></Route>
              <Route
                path="/find-courses/:category"
                exact
                component={AllCourse}
              ></Route>
              <Route
                path="/course-details/:slug"
                exact
                component={CourseDetails}
              ></Route>
              <Route path="/contact-us" exact component={Contact}></Route>
              <Route path="/blogs/:slug" exact component={BlogDetails}></Route>
              {/* <Route path="/abroad-details" exact component={AbroadInner}></Route> */}
            </Switch>
          </ScrollToTop>
        </Router>
        {/* <Contact></Contact> */}
      </div>
    </Provider>
  );
}

export default App;
