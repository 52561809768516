import React, { Component } from "react";

export default class SideEnq extends Component {
  render() {
    return (
      <div className="side_enq_form">
        <h6 className="heading">Quick Enquiry</h6>
        <p>Lorem ipsum dolor sit amet, consectet urqui sacu.</p>
        <div class="mb-2">
          <label class="form-label">
            Name<span>*</span>
          </label>
          <input type="text" class="form-control enq_inp" placeholder="" />
        </div>
        <div class="mb-2">
          <label class="form-label">
            Email<span>*</span>
          </label>
          <input type="text" class="form-control enq_inp" placeholder="" />
        </div>
        {/* <div class="mb-2">
          <label class="form-label">
            Country Code<span>*</span>
          </label>
          <select class="form-select w-100 enq_inp" aria-label="Default select example">
            <option value="1">+91</option>
            <option value="2">+975</option>
            <option value="3">+1</option>
          </select>
        </div> */}
        <div class="mb-2">
          <label class="form-label">
            Phone<span>*</span>
          </label>
          <input type="text" class="form-control enq_inp" placeholder="" />
        </div>
        {this.props.ctryList ? (
          <div class="mb-2">
            <label class="form-label">
              Coutry<span>*</span>
            </label>
            <select
              class="form-select w-100 enq_inp"
              aria-label="Default select example"
            >
              {this.props.ctryList.map((ctry, i) => (
                <option
                  value={ctry.name}
                  selected={this.props.slug === ctry.slug ? true : false}
                >
                  {ctry.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div class="mb-2">
            <label class="form-label">
              Coutry<span>*</span>
            </label>
            <select
              class="form-select w-100 enq_inp"
              aria-label="Default select example"
            >
              <option value="1">India</option>
              <option value="2">UK</option>
              <option value="3">US</option>
            </select>
          </div>
        )}

        {/* <div class="mb-2">
          <label class="form-label">
            Nearest Office<span>*</span>
          </label>
          <select class="form-select w-100 enq_inp" aria-label="Default select example">
            <option value="1">Kanjirapally</option>
            <option value="2">Kottayam</option>
            <option value="3">Ernakulam</option>
          </select>
        </div>
        <div class="mb-2">
          <label class="form-label">
            Service Looking For<span>*</span>
          </label>
          <select class="form-select w-100 enq_inp" aria-label="Default select example">
            <option value="1">Overseas Education</option>
          </select>
        </div> */}
        <div className="pt-2">
          <button type="button" class="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    );
  }
}
