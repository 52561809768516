import React, { Component } from "react";

export default class counterCard extends Component {
  render() {
    return (
      <div class="card">
        <div class="card-body">
          <div className="mission_no_wrapper">
            <div className="box"></div>
            <p>{this.props.num}</p>
          </div>
          <h5 class="card-title">Card title</h5>

          <p class="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p>
        </div>
      </div>
    );
  }
}
