import React, { Component } from "react";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import BlogsSec from "../pageCmp/home/BlogsSec";
import ServicesSec from "../pageCmp/home/ServicesSec";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class Contact extends Component {
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  render() {
    return (
      <>
        <TopNav></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper">
          <div className="container">
            <div className="contact_us_wrapper">
              <h6 className="sm_head">Get excited already!!</h6>
              <h4 className="lg_head">Reach me!</h4>
              <div className="row mt-4">
                <div className="col-lg-4">
                  <div className="row">
                    <div class="col-sm-2">
                      <div className="icon_wrpr">
                        <i className="icomoon icon-phone-incoming"></i>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div className="contact_info">
                        <h6>Contact</h6>
                        <p>
                          Mobile: <span>+91 1234567890</span>
                        </p>
                        <p>
                          Hotline: <span>+91 1234567890</span>
                        </p>
                        <p>
                          Mail: <span>contact@gmail.com</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div class="col-sm-2">
                      <div className="icon_wrpr">
                        <i className="icomoon icon-clock"></i>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div className="contact_info">
                        <h6>Hours of operation</h6>
                        <p>Monday - Friday : 09:00 - 20:00</p>
                        <p>Saturday - Sunday : 11:00 - 20:00</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div class="col-sm-2">
                      <div className="icon_wrpr">
                        <i className="icomoon icon-pin"></i>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div className="contact_info">
                        <h6>Address</h6>
                        <p>
                          Akshya Nagar 1st Block 1st Cross, Rammurthy nagar,
                          Bangalore-560016
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact_form_wrapper mb-5">
                <div className="row mt-5 d-flex justify-content-between">
                  <div className="col-lg-7">
                    <form class="row g-3 ">
                      <div class="col-md-6">
                        <input
                          type="text"
                          class="form-control contact_inp"
                          placeholder="Name *"
                        />
                      </div>
                      <div class="col-md-6">
                        <input
                          type="email"
                          class="form-control contact_inp"
                          placeholder="Email *"
                        />
                      </div>
                      <div class="col-12 mt-3">
                        <input
                          type="text"
                          class="form-control contact_inp"
                          placeholder="Subject *"
                        />
                      </div>
                      <div class="col-12 mt-3">
                        <textarea
                          class="form-control contact_textarea"
                          placeholder="Leave a comment here"
                          rows={8}
                          // style="height: 100px"
                        ></textarea>
                      </div>

                      <div class="col-12 mt-3">
                        <button type="submit" class="btn btn-primary btn-lg">
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-5">
                    <div className="img_wrapper">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/images/imgs/contact_img.png"
                        }
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideFooter={true}></Footer>
      </>
    );
  }
}
