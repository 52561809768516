import React, { Component } from "react";
import Footer from "../components/commonCmp/Footer";
import SideEnq from "../components/commonCmp/SideEnq";
import TopNav from "../components/commonCmp/TopNav";
import BlogsSec from "../pageCmp/home/BlogsSec";
import ServicesSec from "../pageCmp/home/ServicesSec";
import { connect } from "react-redux";
import { getCoursesDt } from "../redux/actions/dataAction";
import { ScrollTrigger } from "gsap/ScrollTrigger";

class CourseDetails extends Component {
  constructor() {
    super();
    this.state = {
      courseInfo: null,
    };
  }
  componentDidMount() {
    console.log(this.props.match.params.slug);
    this.props.getCoursesDt({ slug: this.props.match.params.slug });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.courses !== this.props.courses) {
      if (nextProps.courses) {
        this.setState({
          courseInfo: nextProps.courses,
        });
      }
    }
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  render() {
    console.log(this.state.courseInfo);
    return this.state.courseInfo ? (
      <>
        <TopNav></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper">
          <div className="container">
            <div className="course_details_wrapper">
              <h4 className="mb-3">{this.state.courseInfo.name}</h4>
              <div className="row">
                <div className="col-lg-2">
                  <div className="course_img_wrapper">
                    <img src={this.state.courseInfo.image_url}></img>
                  </div>
                  <p className="course_price_title">Total Course Fee</p>
                  <p className="course_price_amt">$1200</p>
                </div>
                <div className="col-lg-6">
                  <div className="course_info">
                    <div className="course_tag_wrapper">
                      <div className="tag_item">
                        <i className="icomoon icon-education"></i>
                        <span>
                          {this.state.courseInfo.education_level_name}
                        </span>
                      </div>
                      <div className="tag_item ml-4">
                        <i className="icomoon icon-calendar"></i>
                        <span>{this.state.courseInfo.course_years} year</span>
                      </div>
                    </div>
                    <div className="mt-4 pb-5">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.courseInfo.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-5">
                  <SideEnq></SideEnq>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    ) : (
      <p>Loading..</p>
    );
  }
}

const mapStateToProps = (state) => ({
  courses: state.data.coursesDt,
});

export default connect(mapStateToProps, {
  getCoursesDt,
})(CourseDetails);
