import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class HomNav extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
    };
  }
  render() {
    return (
      <nav class="navbar navbar-expand-lg navbar-light">
        {this.state.showMenu && <div className="overlay" onClick={() => this.setState({showMenu:false})}></div>}
        <div class="container">
          <a class="navbar-brand" href="#">
            <img
              src={process.env.PUBLIC_URL + "assets/images/logo/logo.png"}
            ></img>
          </a>
          <button
            onClick={() =>
              this.setState({
                showMenu: true,
              })
            }
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class={`collapse navbar-collapse ${this.state.showMenu && "show"}`}
            id="navbarNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                <Link class="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/study-abroad">
                  Study Abroad
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/courses">
                  Find a Course
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/about-us">
                  About Us
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/blogs">
                  Blogs
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/services">
                  Our Services
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
