import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

export default class QnASec extends Component {
  render() {
    return (
      <div className="qa_main_wrapper">
        <div className="container pt-5 pb-5">
          <div className="sec_heading_wrapper">
            <div className="d-flex flex-column align-items-center">
              <p className="sm_head">FAQ</p>
              <h1 className="main_heabing">Do we offer at the OverseasEye</h1>
            </div>
          </div>
          <div className="faq_accordion_wrapper mt-5">
            <Accordion>
              <div className="row">
                <div className="col-lg-6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <i className="icomoon icon-minus1"></i>
                            ) : (
                              <i className="icomoon icon-plus1"></i>
                            )
                          }
                        </AccordionItemState>
                        What harsh truths do you prefer to ignore?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col-lg-6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <i className="icomoon icon-minus1"></i>
                            ) : (
                              <i className="icomoon icon-plus1"></i>
                            )
                          }
                        </AccordionItemState>
                        What harsh truths do you prefer to ignore?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col-lg-6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <i className="icomoon icon-minus1"></i>
                            ) : (
                              <i className="icomoon icon-plus1"></i>
                            )
                          }
                        </AccordionItemState>
                        What harsh truths do you prefer to ignore?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col-lg-6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <i className="icomoon icon-minus1"></i>
                            ) : (
                              <i className="icomoon icon-plus1"></i>
                            )
                          }
                        </AccordionItemState>
                        What harsh truths do you prefer to ignore?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col-lg-6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <i className="icomoon icon-minus1"></i>
                            ) : (
                              <i className="icomoon icon-plus1"></i>
                            )
                          }
                        </AccordionItemState>
                        What harsh truths do you prefer to ignore?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col-lg-6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <i className="icomoon icon-minus1"></i>
                            ) : (
                              <i className="icomoon icon-plus1"></i>
                            )
                          }
                        </AccordionItemState>
                        What harsh truths do you prefer to ignore?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}
