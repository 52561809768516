import React, { Component } from "react";
import ServiceCard from "../../components/card/ServiceCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TimelineLite, CSSPlugin } from "gsap/all";

export default class ServicesSec extends Component {
  constructor(props) {
    super(props);
    // cards, elements tha will be used in the tween
    this.cards = [];
    // the timeline instance
    // this.tl = new TimelineLite({ paused: true });
  }
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    // this.tl = new TimelineLite({ paused: true });

    // this.tl.staggerTo( this.cards , 0.5, { autoAlpha: 1, y: -20 }, 0.1);

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".service_sec_wrapper",
        start: "top center",
        // end: "bottom bottom",
        // once:true,
        // scrub: true,
        markers: false,
      },
    });
    tl.fromTo(
      this.cards,
       {
         opacity: 0,
         y: 40,
         ease: "power1.out"
       },
       {
         opacity: 1,
         y: 0,
         ease: "power1.out",
         stagger: 0.05
       }
     );
    // tl.staggerTo(this.cards, 0.5, { autoAlpha: 1, y: -50 }, 0.1);
  }
  render() {
    // this.tl.kill().clear().pause(0);
    return (
      <div className="service_sec_wrapper mt-4 mb-4">
        <div className="container">
          <div className="sec_heading_wrapper">
            <div className="d-flex flex-column align-items-center">
              <p className="pill_head pr_bg_cl" onClick={() => this.tl.play()}>
                What
              </p>
              <h1 className="main_heabing">Do we offer at the OverseasEye</h1>
              <p className="sub_heabing">
                We guide and help you to achieve your dream come true
              </p>
            </div>
          </div>
          <div className="mt-5">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4" ref={(div) => (this.cards[0] = div)}>
                <ServiceCard
                  img={process.env.PUBLIC_URL + "assets/images/icons/chart.svg"}
                  bg_color={"color1"}
                  heading={"Career Counseling"}
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[1] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[1] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/chats-teardrop-duotone.svg"
                  }
                  bg_color={"color2"}
                  heading={"Visa Assistance"}
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[2] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[2] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/medal-duotone.svg"
                  }
                  bg_color={"color3"}
                  heading={"Scholarships"}
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[3] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[3] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/users-duotone.svg"
                  }
                  bg_color={"color4"}
                  heading={"Training & Coaching"}
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[4] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[4] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/book-bookmark-duotone.svg"
                  }
                  bg_color={"color5"}
                  heading={"Education Loan"}
                ></ServiceCard>
              </div>
              <div className="col-lg-4" ref={(div) => (this.cards[5] = div)}>
                <ServiceCard
                  // ref={(div) => (this.cards[5] = div)}
                  img={
                    process.env.PUBLIC_URL +
                    "assets/images/icons/clipboard-text-duotone.svg"
                  }
                  bg_color={"color6"}
                  heading={"Visa Attestation"}
                ></ServiceCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
