import React, { Component } from "react";

export default class CourseCardCat extends Component {
  render() {
    return (
      // <div >
      <div
        className="category_card_wrapper"
        onClick={
          this.props.slug === ""
            ? () => this.props.history.push(
                "/find-courses/" + this.props.cat.id
              )
            : () =>
                this.props.history.push(
                  "/find-courses/" + this.props.slug + "/" + this.props.cat.id
                )
        }
      >
        <div className="img_wrapper">
          <img src={this.props.cat.image_url}></img>
          <p className="category_title">{this.props.cat.name}</p>
        </div>
      </div>
      // </div>
    );
  }
}
