import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../components/commonCmp/Footer";
import HomeSlider from "../components/slider/HomeSlider";
import BannerSec1 from "../pageCmp/home/BannerSec1";
import BannerSec2 from "../pageCmp/home/BannerSec2";
import BlogsSec from "../pageCmp/home/BlogsSec";
import PartnersSec from "../pageCmp/home/PartnersSec";
import QnASec from "../pageCmp/home/QnASec";
import ServicesSec from "../pageCmp/home/ServicesSec";
import Testimonial from "../pageCmp/home/Testimonial";
import {
  getHomeSlider,
  getBlogList,
  getTestiList,
  getCareerList,
  getPartners,
} from "../redux/actions/dataAction";
import isEmpty from "../redux/validation/is-Empty";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Loader from "../components/commonCmp/Loader";
import store from "../redux/store";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      slider: null,
      blogs: null,
      testi: null,
      partners: null,
    };
  }
  componentDidMount() {
    const {
      homeSlider,
      partner_list,
      testi_list,
      blog_list,
    } = store.getState().data;
    console.log(homeSlider);
    if (homeSlider) {
      this.setState({
        slider: homeSlider,
      });
    } else {
      this.props.getHomeSlider();
    }

    if (partner_list) {
      this.setState({
        partners: partner_list,
      });
    } else {
      this.props.getPartners();
    }

    if (testi_list) {
      this.setState({
        testi: testi_list,
      });
    } else {
      this.props.getTestiList();
    }

    if (blog_list) {
      this.setState({
        blogs: blog_list,
      });
    } else {
      this.props.getBlogList({ page: 1, pagination_limit: 3 });
    }

    // this.props.getTestiList();
    // this.props.getBlogList({ page: 1, pagination_limit: 3 });
    this.props.getCareerList();
    // this.props.getPartners();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.slider !== this.props.slider) {
      this.setState({
        slider: nextProps.slider,
      });
    }
    if (nextProps.blogs !== this.props.blogs) {
      this.setState({
        blogs: nextProps.blogs,
      });
    }
    if (nextProps.testi !== this.props.testi) {
      this.setState({
        testi: nextProps.testi,
      });
    }
    if (nextProps.partner !== this.props.partner) {
      this.setState({
        partners: nextProps.partner,
      });
    }
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  render() {
    return !isEmpty(this.state.slider) &&
      !isEmpty(this.state.testi) &&
      !isEmpty(this.state.blogs) &&
      !isEmpty(this.state.partners) ? (
      <>
        {/* <Loader></Loader> */}
        <HomeSlider
          slider={this.state.slider}
          history={this.props.history}
        ></HomeSlider>
        <BannerSec1></BannerSec1>
        <BannerSec2></BannerSec2>
        <Testimonial testi={this.state.testi}></Testimonial>
        <ServicesSec></ServicesSec>
        <QnASec></QnASec>
        <BlogsSec
          history={this.props.history}
          blogs={this.state.blogs}
        ></BlogsSec>
        <PartnersSec partners={this.state.partners}></PartnersSec>
        <Footer></Footer>
      </>
    ) : (
      <Loader></Loader>
    );
  }
}
const mapStateToProps = (state) => ({
  slider: state.data.homeSlider,
  blogs: state.data.blog_list,
  testi: state.data.testi_list,
  career: state.data.career_list,
  partner: state.data.partner_list,
});

export default connect(mapStateToProps, {
  getHomeSlider,
  getBlogList,
  getTestiList,
  getCareerList,
  getPartners,
})(Home);
