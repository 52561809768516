import React, { Component } from "react";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import ServicesSec from "../pageCmp/home/ServicesSec";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class Services extends Component {
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  render() {
    return (
      <>
        <TopNav></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper">
          <ServicesSec></ServicesSec>
        </div>
        <Footer></Footer>
      </>
    );
  }
}
