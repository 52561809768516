import React, { Component } from "react";
import CourseCard from "../components/card/CourseCard";
import Footer from "../components/commonCmp/Footer";
import SideEnq from "../components/commonCmp/SideEnq";
import TopNav from "../components/commonCmp/TopNav";
import { connect } from "react-redux";
import { getGeneralDetails } from "../redux/actions/dataAction";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CourseSec from "../pageCmp/Course/CourseSec";


class General extends Component {
  constructor() {
    super();
    this.state = {
      details: null,
      slug: null,
    };

    this.cards = [];
  }

  componentDidMount() {
    console.log(this.props.match.params.slug);
    this.setState({
      slug: this.props.match.params.slug,
    });
    this.props.getGeneralDetails({ slug: this.props.match.params.slug });
    gsap.registerPlugin(ScrollTrigger);
    // ScrollTrigger.getAll().forEach(t => t.kill());

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".ttee",
        start: "top center",
        markers: false,
      },
    });
    tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.details !== this.props.details) {
      if (nextProps.details) {
        this.setState({
          details: nextProps.details,
        });
      }
    }
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }

  render() {
    console.log(this.state.details);
    return this.state.details ? (
      <>
        <TopNav></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper crse_lst mt-5">
          <div className="top_banner">
            <img src={this.state.details.image_url}></img>
            <div className="heading">
              <div className="container">
                <h1 className="heading_text">{this.state.details.title}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="abroad_info_wrapper pt-5 pb-5">
              <div className="row">
              
                <div
                  className={`col-lg-8 ${this.state.tab === 1 && "col-lg-12"}`}
                >
                
                    <div className="abroad_info ">
                    <h4 className="mb-4">{this.state.details.title}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.details.description,
                        }}
                      ></p>
                    </div>
                  
                </div>
                
                  <div className="col-lg-4">
                    <SideEnq
                      ctryList={this.state.ctryList}
                      slug={this.state.slug}
                    ></SideEnq>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    ) : (
      <p>Loading..</p>
    );
  }
}

const mapStateToProps = (state) => ({
  details: state.data.general_dt,
});

export default connect(mapStateToProps, {
  getGeneralDetails,
})(General);
