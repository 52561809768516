import React, { Component } from "react";


export default class CourseCard extends Component {
  render() {
    return (
      // <div className="course_card_wrapper">
      <>
        <div className="img_wrapper">
          <img
            src={this.props.data.image_url}
          ></img>
        </div>
        <div className="course_info">
          <h6 className="course_title">{this.props.data.name}</h6>
          <div className="course_tag_wrapper">
            <div className="tag_item">
              <i className="icomoon icon-education"></i>
              <span>{this.props.data.education_level_name}</span>
            </div>
            <div className="tag_item ml-4">
              <i className="icomoon icon-calendar"></i>
              <span>{this.props.data.course_years} year</span>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: this.props.data.description }}
          />
          {/* <p className="desc">{}</p> */}
          <div className="mt-3">
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => this.props.history.push("/course-details/"+this.props.data.slug)}
            >
              See More
            </button>
          </div>
        </div>
      </>
      // </div>
    );
  }
}
