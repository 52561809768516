import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="ft_main_wrapper">
        {!this.props.hideFooter && (
          <div className="container">
            <div className="position-relative">
              <div className="contact_sm_wrapper ">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-lg-4">
                    <div className="contact_sm_container">
                      <div>
                        <p className="sm_heading">Contact us</p>
                        <h1 className="main_heading">You need any Help?</h1>
                      </div>
                      <div className="img_wrapper">
                        <img
                          src={
                            '../../' +
                            process.env.PUBLIC_URL +
                            "assets/images/imgs/contact_img1.svg"
                          }
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <form className="contact_sm_form">
                      <div class="mb-3">
                        <input
                          type="text"
                          class="form-control overseas_input"
                          placeholder="Name"
                        />
                      </div>
                      <div class="mb-3">
                        <input
                          type="email"
                          class="form-control overseas_input"
                          placeholder="Email"
                        />
                      </div>
                      <div class="mb-3">
                        <input
                          type="email"
                          class="form-control overseas_input"
                          placeholder="Phone Number"
                        />
                      </div>
                      <div class="">
                        <button
                          type="submit"
                          class="btn btn-primary mb-3 w-100 fw-800 d-flex justify-content-between align-items-center"
                        >
                          Send{" "}
                          <i className="icomoon icon-chevron-right fw-800 overseas_btn"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <footer>
          <div class="container py-5 ">
            <div className="footer_main_wrapper">
              <div className="footer_wrapper">
                <div class="row">
                  <div class="col-12 col-md">
                    <div className="img_wrapper">
                      <img
                        src={
                          '../../' +
                          process.env.PUBLIC_URL +
                          "assets/images/logo/logo.png"
                        }
                      ></img>
                    </div>
                  </div>
                  <div class="col-6 col-md">
                    <h5 className="text-white">
                      <b>Resources</b>
                    </h5>
                    <ul class="list-unstyled text-small">
                      <li>
                        <a class="" href="#">
                          Home
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          About Us
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          Find a Course
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          Locate Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6 col-md">
                    <h5 className="text-white">
                      <b>Study Abord</b>
                    </h5>
                    <ul class="list-unstyled text-small">
                      <li>
                        <a class="" href="#">
                          Canada
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          UK
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          US
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          Singapore
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6 col-md">
                    <h5 className="text-white">
                      <b>Our Services</b>
                    </h5>
                    <ul class="list-unstyled text-small">
                      <li>
                        <a class="" href="#">
                          Career Counsling
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          NMC Test
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          Visa Assistance
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          IELTS training
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <p class=" mb-0 mt-5 text-muted">
                    Copyright &copy; 2021 OverseasEye All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
