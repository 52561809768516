import React, { Component } from "react";
import CounterCard from "../../components/card/counterCard";

export default class MissionSec extends Component {
  render() {
    return (
      <div className="container">
        <div className="mission_wrapper row">
          <div className="mission_card_container col-lg-3 col-md-4">
            <CounterCard num={'01'}></CounterCard>
          </div>
          <div className="mission_card_container col-lg-3 col-md-4">
            <CounterCard num={'02'}></CounterCard>
          </div>
          <div className="mission_card_container col-lg-3 col-md-4">
            <CounterCard num={'03'}></CounterCard>
          </div>
          <div className="mission_card_container col-lg-3 col-md-4">
            <CounterCard num={'04'}></CounterCard>
          </div>
          
          
        </div>
      </div>
    );
  }
}
