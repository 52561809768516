import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import BlogsSec from "../pageCmp/home/BlogsSec";
import { getBlogList } from "../redux/actions/dataAction";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import NetflixLoader from "../components/Skeleton/ListSkeleton";

class Blog extends Component {
  constructor() {
    super();
    this.state = {
      blogs: null,
    };
  }
  componentDidMount() {
    this.props.getBlogList({ page: 1, pagination_limit: 10 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.slider !== this.props.slider) {
      this.setState({
        slider: nextProps.slider,
      });
    }
    if (nextProps.blogs !== this.props.blogs) {
      this.setState({
        blogs: nextProps.blogs,
      });
    }
    if (nextProps.testi !== this.props.testi) {
      this.setState({
        testi: nextProps.testi,
      });
    }
  }
  componentWillUnmount() {
    // console.log(ScrollTrigger.getAll())
    try {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    } catch {}
  }
  render() {
    return (
      <>
        <TopNav></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper">
          {this.state.blogs ? (
            <BlogsSec
              main={true}
              history={this.props.history}
              blogs={this.state.blogs}
            ></BlogsSec>
          ) : (
            
                <div className={`bolgs_sec_wrapper ${!this.props.main && "pt-5 pb-2"}`}>
                <div className="container">
                  <div className="sec_heading_wrapper">
                    <div className="d-flex flex-column align-items-center">
                      <p className="pill_head pr_bg_cl">Blogs</p>
                      {/* <h1 className="main_heabing">Do we offer at the OverseasEye</h1> */}
                      <p className="sub_heabing">Don’t miss our recent articles</p>
                    </div>
                  </div>
                  <div className="mt-5 mb-5">
                    <NetflixLoader></NetflixLoader>
                  </div>
                </div>
              </div>
          )}
        </div>
        <Footer></Footer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  slider: state.data.homeSlider,
  blogs: state.data.blog_list,
  testi: state.data.testi_list,
  career: state.data.career_list,
});

export default connect(mapStateToProps, {
  getBlogList,
})(Blog);
