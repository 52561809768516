import React, { Component } from "react";
import CourseCard from "../components/card/CourseCard";
import Footer from "../components/commonCmp/Footer";
import TopNav from "../components/commonCmp/TopNav";
import ServicesSec from "../pageCmp/home/ServicesSec";
import Select from "react-select";
import { connect } from "react-redux";
import { getCategoryList } from "../redux/actions/dataAction";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CourseSec from "../pageCmp/Course/CourseSec";
import Loader from "../components/commonCmp/Loader";

const options = [
  // { value: "Select The Courses...", label: "Select The Courses..." },
  { value: "One", label: "One" },
  { value: "Two", label: "Two" },
  { value: "Three", label: "Three" },
];
const placeholder = "Select The Courses...";
const placeholderCtry = "Select Country...";

class CourseCategory extends Component {
  constructor() {
    super();
    this.state = {
      category: null,
    };
    this.cards = [];
  }
  componentDidMount() {
    this.props.getCategoryList();
    gsap.registerPlugin(ScrollTrigger);

    this.tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".all_crse",
        start: "top center",
        markers: false,
      },
    });
    this.tl.fromTo(
      this.cards,
      {
        opacity: 0,
        y: 40,
        ease: "power1.out",
      },
      {
        opacity: 1,
        y: 0,
        ease: "power1.out",
        stagger: 0.05,
      }
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);

    if (nextProps.cat !== this.props.cat) {
      this.setState({
        category: nextProps.cat,
      });
    }
  }
  selectCounrty(value) {
    this.setState({
      ctryId: value.value,
    });
    this.props.getEduLevel({ country: value.value });
  }
  edChange(value) {
    this.setState({
      eduId: value.value,
    });
    this.props.getCourses({ education_level_id: value.value });
    // this.props.getEduLevel({ country: value.value });
  }
  render() {
    // var ctrys = [{ value: "", label: "Select Country" }];
    var ctrys = [];
    var ed_lvl = [];
    if (this.state.ctryList) {
      this.state.ctryList.map((ctry, i) =>
        ctrys.push({ value: ctry.id, label: ctry.name })
      );
    }
    if (this.state.ed_level) {
      this.state.ed_level.map((ed, i) =>
        ed_lvl.push({ value: ed.id, label: ed.name })
      );
    }

    console.log(this.state);
    return this.state.category ? (
      <>
        <TopNav></TopNav>
        {/* <Footer></Footer> */}
        <div className="content_wrapper all_crse mt-0">
          <div className="course_list_wrapper">
            <div className="container mt-5 mb-5 testt">
              {/* <h4>All Courses</h4>
              <p className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididun. Quis ipsum suspendisse ultrices
                gravida. Risus commodo viverra maecenas accumsan lacus vel
                facilisis.{" "}
              </p> */}
              <div className="sec_heading_wrapper pt-5">
                <div className="d-flex flex-column align-items-center">
                  <p className="pill_head pr_bg_cl">All Courses</p>
                  {/* <h1 className="main_heabing">Do we offer at the OverseasEye</h1> */}
                  <p className="sub_heabing">Don’t miss our recent articles</p>
                </div>
              </div>
              <div className="mt-4">
                <CourseSec category={this.state.category} history={this.props.history} slug=""></CourseSec>
              </div>
              {/* <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard>
              <CourseCard history={this.props.history}></CourseCard> */}
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    ) : (
      <Loader></Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  cat: state.data.course_list,
});

export default connect(mapStateToProps, {
  getCategoryList,
})(CourseCategory);
